export default (e, defaultMessage = "") => {
    if (typeof e === "string") {
        return e;
    } else if (e?.response?.data?.error) {
        return e.response.data.error.message || e.response.data.error;
    } else if (e?.error) {
        return e?.error;
    } else if (e?.message === "Network Error") {
        return "Network Error. Please check your internet connection or contact us at support@songfinch.com.";
    } else {
        return e?.message || defaultMessage || "Sorry, something went wrong. Please try again or contact us at support@songfinch.com.";
    }
};
